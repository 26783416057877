<template>
  <div class="eventManagement-form-box">
    <div class="form-left">
        <head-layout :show-icon="false" head-title="处理进度"></head-layout>
        <el-steps direction="vertical" :active="active" style="margin-top: 30px;align-items: center;height: 50%;">
          <el-step title="组织上报"></el-step>
          <el-step title="调查处理"></el-step>
          <el-step title="整改"></el-step>
          <el-step title="验收"></el-step>
        </el-steps>
    </div>
    <div class="form-right">
      <head-layout
        head-title="事故管理"
        :head-btn-options="headBtnOptions"
        @head-save="headSave(false)"
        @head-saveBack="headSave(true)"
        @head-cancel="headCancel"
      ></head-layout>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="基本信息" name="1">
            <el-form
              ref="dataForm"
              :model="dataForm"
              label-width="96px"
              :disabled="formType == 'view' || formType == 'process'"
              :rules="rules"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事故编号" prop="accCode">
                    <el-input
                      v-model="dataForm.accCode"
                      :disabled="
                        dataForm.isAutomatic || pageDisabled || dataForm.id
                      "
                      placeholder="请填写事故编号"
                    >
                      <template slot="append">
                        自动生成
                        <el-switch
                          :disabled="pageDisabled || dataForm.id"
                          v-model="dataForm.isAutomatic"
                          active-color="#13ce66"
                          @change="handleGetCode"
                        >
                        </el-switch>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="发生时间" prop="accTime">
                    <el-date-picker
                      type="datetime"
                      v-model="dataForm.accTime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="请选择报告时间"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="事故标题" prop="accTitle">
                    <el-input
                      v-model="dataForm.accTitle"
                      :title="dataForm.accTitle"
                      maxlength="100"
                      placeholder="请填写事故标题"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8">
                  <el-form-item label="发生地点" prop="address">
                    <el-input
                      v-model="dataForm.address"
                      :title="dataForm.address"
                      maxlength="60"
                      placeholder="请填写发生地点"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事发单位" prop="accOrgIdName">
                    <el-input v-model="dataForm.accOrgIdName" @focus="openDialog('deptDialog',{id:'accOrgId',name:'accOrgIdName'})"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事发班组" prop="accClass">
                    <el-input v-model="dataForm.accClass"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告人" prop="userName">
                    <el-input
                      v-model="dataForm.userName"
                      @focus="openDialog('userDialog',{id:'userId',name:'userName'})"
                      placeholder="请选择报告人"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>

                <el-col :span="8">
                  <el-form-item label="伤害程度" prop="casualty">
                    <avue-select
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      v-model="dataForm.casualty"
                      placeholder="请选择伤害程度"
                      :dic="casualtyArr"
                    ></avue-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="上报人电话" prop="tel">
                    <el-input v-model.number="dataForm.tel" maxlength="11"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="事故描述" prop="brifDesc">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.brifDesc"
                      maxlength="225"
                      placeholder="请输入事故描述"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="其他需要报告" prop="otherReport">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.otherReport"
                      maxlength="225"
                      placeholder="请输入其他需要报告"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="伤亡损失情况" prop="loss">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.loss"
                      maxlength="225"
                      placeholder="请输入事故人员和财产损失的基本情况"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="事故处理措施" prop="treatment">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.treatment"
                      maxlength="225"
                      placeholder="请输入事故发生后采取的处理措施及事故控制情况"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="现场情况" prop="treeData">
                    <el-upload
                      v-if="formType != 'view'"
                      accept=".jpg,.mp4,.png"
                      style="
                        margin-bottom: 12px;
                        display: flex;
                        align-items: center;
                      "
                      class="upload-demo"
                      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                      :show-file-list="false"
                      :before-upload="checkFileType"
                      :on-success="handleAvatarSuccess"
                      ref="uploadRef1"
                      :headers="headers"
                    >
                      <el-button size="small" type="primary" icon="el-icon-upload"
                        >点击上传
                      </el-button>
                    </el-upload>
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img :src="item.thumbnailLink" alt="" />
                        <i
                          v-if="formType !== 'view'"
                          class="el-icon-circle-close delete-icon"
                          @click.stop="handleRemove(item, index)"
                        ></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </el-collapse-item>
        <el-collapse-item title="事故调查" name="2" v-if="dataForm.id && dataForm.accStatus !== 'PREPARE'">
            <el-form
              ref="dataForm1"
              :model="dataForm"
              label-width="130px"
              :disabled="formType == 'view'"
              :rules="rules"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="组织内调查人员" prop="orgResearchUser">
                      <el-input v-model="dataForm.orgResearchUser"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="组织外调查人员" prop="orgOutResearchUser">
                      <el-input v-model="dataForm.orgOutResearchUser"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事发部门" prop="deptIdName">
                      <el-input v-model="dataForm.deptIdName" @focus="openDialog('deptDialog',{id:'deptId',name:'deptIdName'})"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事故类别" prop="accType">
                    <avue-select
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      v-model="dataForm.accType"
                      placeholder="请选择事故类别"
                      type="tree"
                      :dic="acc_record_type"
                    ></avue-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事故级别" prop="accLevel">
                    <div class="flex-container flex-align-c">
                      <avue-select
                        :props="{
                          label: 'dictValue',
                          value: 'dictKey',
                        }"
                        v-model="dataForm.accLevel"
                        placeholder="请选择事故级别"
                        type="tree"
                        :dic="acc_record_level"
                      ></avue-select>
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <template #content>
                          <span v-html="accLevelText"></span>
                        </template>
                        <i class="el-icon-question" style="font-size: 24px"></i>
                      </el-tooltip>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事故性质" prop="accSource">
                    <avue-select
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      v-model="dataForm.accSource"
                      placeholder="请选择事故性质"
                      :dic="accSourceArr"
                    ></avue-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事故作业类别" prop="accWorkType">
                    <avue-select
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      v-model="dataForm.accWorkType"
                      placeholder="请选择事故作业类别"
                      :dic="accWorkTypeArr"
                    ></avue-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事故原因" prop="reason">
                    <div class="flex-container flex-align-c">
                      <avue-select
                        :props="{
                          label: 'dictValue',
                          value: 'dictKey',
                        }"
                        v-model="dataForm.reason"
                        placeholder="请选择事故原因"
                        :dic="reasonArr"
                      ></avue-select>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="事故责任人" prop="accResponsUserIdName">
                    <el-input v-model="dataForm.accResponsUserIdName" @focus="openDialog('userDialog',{id:'accResponsUserId',name:'accResponsUserIdName'})"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="死亡人数" prop="lossDeath">
                    <el-input-number
                      style="width: 100%"
                      :min="0"
                      step-strictly
                      :step="1"
                      :max="9999"
                      v-model.number="dataForm.lossDeath"
                      type="number"
                      placeholder="请填写死亡人数"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="失踪人数" prop="lossMiss">
                    <el-input-number
                      style="width: 100%"
                      :min="0"
                      step-strictly
                      :step="1"
                      :max="9999"
                      type="number"
                      v-model.number="dataForm.lossMiss"
                      placeholder="请填写失踪人数"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="中毒人数" prop="lossPoison">
                    <el-input-number
                      style="width: 100%"
                      :min="0"
                      step-strictly
                      :step="1"
                      :max="9999"
                      type="number"
                      v-model.number="dataForm.lossPoison"
                      placeholder="请填写中毒人数"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="直接经济损失" prop="lossFa">
                    <el-input
                      min="0"
                      type="number"
                      v-model="dataForm.lossFa"
                      :title="dataForm.lossFa"
                      placeholder="请输入直接经济损失评估结果"
                    >
                      <template slot="append">万元</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="间接经济损失" prop="lossIndirect">
                    <div class="flex-container flex-align-c">
                    <el-input-number
                      min="0"
                      style="width: 100%;"
                      :controls="false"
                      v-model="dataForm.lossIndirect"
                      placeholder="请输入间接经济损失评估结果"
                    >
                    </el-input-number>
                      <span class="lossIndirect">万元</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="损失工作日" prop="lossTime">
                    <div class="flex-container flex-align-c">
                      <el-input-number
                        min="0"
                        style="width: 100%;"
                        :controls="false"
                        v-model="dataForm.lossTime"
                        :title="dataForm.lossTime"
                        placeholder="请输入损失工作日"
                      >

                      </el-input-number>
                      <span class="lossIndirect">H</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="其他损失" prop="lossOther">
                    <el-input
                      v-model="dataForm.lossOther"
                      placeholder="请输入其他损失描述信息"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="社保工伤赔付" prop="ssCompensate">
                    <div class="flex-container flex-align-c">
                    <el-input-number
                      min="0"
                      style="width: 100%;"
                      :controls="false"
                      v-model="dataForm.ssCompensate"
                      placeholder="请输入社保工伤赔付"
                    >
                    </el-input-number>
                      <span class="lossIndirect">万元</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="事故经过" prop="accDesc">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.accDesc"
                      maxlength="225"
                      placeholder="请输入事故发生的详细经过和原因"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="事故直接原因" prop="directReason">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.directReason"
                      maxlength="225"
                      placeholder="请输入事故直接原因"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="事故间接原因" prop="indirectReason">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.indirectReason"
                      maxlength="225"
                      placeholder="请输入事故间接原因"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="根本原因" prop="rootReason">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.rootReason"
                      maxlength="225"
                      placeholder="请输入事故发生的根本原因"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="经验教训" prop="lesson">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.lesson"
                      maxlength="225"
                      placeholder="请输入经验教训"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注（处理意见）" prop="remark">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.remark"
                      maxlength="225"
                      placeholder="请输入备注（处理意见）"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="要求整改措施" prop="rectifyRequest">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.rectifyRequest"
                      maxlength="225"
                      placeholder="请输入要求整改措施"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="责任认定及处理建议" prop="accResp">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.accResp"
                      maxlength="225"
                      placeholder="请输入责任认定和处理建议"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="整改责任部门" prop="rectifyDeptName">
                      <el-input v-model="dataForm.rectifyDeptName" @focus="openDialog('deptDialog',{id:'rectifyDeptId',name:'rectifyDeptName'})" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="整改责任人" prop="rectifyUserName">
                      <el-input v-model="dataForm.rectifyUserName" @focus="openDialog('userDialog',{id:'rectifyUser',name:'rectifyUserName'})"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="整改期限" prop="rectifyLimitDate">
                    <el-date-picker
                      type="datetime"
                      v-model="dataForm.rectifyLimitDate"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="请选择整改期限"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="验收部门" prop="acceptDeptName">
                      <el-input v-model="dataForm.acceptDeptName" @focus="openDialog('deptDialog',{id:'acceptDept',name:'acceptDeptName'})" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="验收责任人" prop="acceptUserName">
                      <el-input v-model="dataForm.acceptUserName" @focus="openDialog('userDialog',{id:'acceptUser',name:'acceptUserName'})"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="调查报告" prop="treeData1">
                    <el-upload
                      :on-preview="handlePreview"
                      :disabled="formType == 'view'"
                      class="upload-demo"
                      accept=".rar,.zip,.doc,.docx,.pdf"
                      action="api/sinoma-resource/oss/endpoint/put-file"
                      :on-success="handleAvatarSuccess1"
                      :before-upload="checkFileType1"
                      :on-remove="handleRemove1"
                      :headers="headers"
                      multiple
                      :file-list="treeData1"
                    >
                      <el-button
                        v-if="formType != 'view'"
                        size="small"
                        type="primary"
                        icon="el-icon-upload"
                        >点击上传
                      </el-button>
                      <div
                        slot="tip"
                        class="el-upload__tip"
                        v-if="formType != 'view'"
                      >
                        支持扩展名：.rar .zip .doc .docx .pdf
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </el-collapse-item>
        <el-collapse-item title="安全处罚措施" name="3" v-if="dataForm.id && dataForm.accStatus !== 'PREPARE'">
            <el-form
              ref="dataForm2"
              :model="dataForm"
              label-width="130px"
              :disabled="formType == 'view'"
              :rules="rules"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="考核对象" prop="assObject">
                      <el-radio-group v-model="dataForm.assObject" @change="groupChange">
                          <el-radio label="人员"></el-radio>
                          <el-radio label="部门"></el-radio>
                      </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm.assObject=='人员'">
                  <el-form-item label="人员" prop="assUserName">
                      <el-input v-model="dataForm.assUserName" @focus="openDialog('userDialog',{id:'assUserId',name:'assUserName'})"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm.assObject=='部门'">
                  <el-form-item label="部门" prop="assDeptName" >
                      <el-input v-model="dataForm.assDeptName" @focus="openDialog('deptDialog',{id:'assDeptId',name:'assDeptName'})"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="扣分" prop="points">
                      <el-input v-model.number="dataForm.points"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="经济处罚（元）" prop="fine">
                      <el-input v-model.number="dataForm.fine"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="教育培训（学时）" prop="eduTrain">
                      <el-input v-model.number="dataForm.eduTrain"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="待岗（月）" prop="awaitJob">
                      <el-input v-model.number="dataForm.awaitJob"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </el-collapse-item>
        <el-collapse-item title="整改信息" name="4" v-if="formType == 'view'">
            <el-form
              ref="dataForm3"
              :model="dataForm"
              label-width="96px"
              :disabled="formType == 'view'"
              :rules="rules"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="整改部门">
                      <el-input v-model="dataForm.rectifyDeptName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="整改人">
                      <el-input v-model="dataForm.hdRectifyVO.actualRectifyUserName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="整改时间">
                    <el-date-picker
                      type="date"
                      v-model="dataForm.hdRectifyVO.rectifyDate"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择整改期限"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="整改结果">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.hdRectifyVO.rectifyMethod"
                      maxlength="225"
                      placeholder="请输入整改结果"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="整改后图片">
                    <template v-for="(item,index) in dataForm.hdRectifyVO.rectifyUrl?JSON.parse(dataForm.hdRectifyVO.rectifyUrl):[].map(item=>{return item.link})">
                      <el-image 
                        style="width: 100px; height: 100px"
                        :src="item" 
                        :preview-src-list="[item]" :key="index">
                      </el-image>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </el-collapse-item>
        <el-collapse-item title="验收信息" name="5" v-if="formType == 'view'">
            <el-form
              ref="dataForm4"
              :model="dataForm"
              label-width="96px"
              :disabled="formType == 'view'"
              :rules="rules"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="验收结果">
                      <el-radio-group v-model="dataForm.hdAcceptVO.acceptFlag">
                        <el-radio label="y">验收通过</el-radio>
                        <el-radio label="n">验收不通过</el-radio>
                      </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="验收时间">
                    <el-date-picker
                      type="date"
                      v-model="dataForm.hdAcceptVO.acceptDate"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择验收时间"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="验收人">
                      <el-input v-model="dataForm.hdAcceptVO.actualAcceptUserName"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="验收意见">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      show-word-limit
                      v-model="dataForm.hdAcceptVO.acceptOpinion"
                      maxlength="225"
                      placeholder="请输入验收意见"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="验收图片">
                  <template v-for="(item,index) in dataForm.hdAcceptVO.acceptUrl?JSON.parse(dataForm.hdAcceptVO.acceptUrl):[].map(item=>{return item.link})">
                      <el-image 
                        style="width: 100px; height: 100px"
                        :src="item" 
                        :preview-src-list="[item]" :key="index">
                      </el-image>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>

    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="userDialog" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptDialog" width="80%">
      <DeptDialog
          ref="DeptDialog"
          :deptCategory="[2, 5]"
          @select-data="deptBack"
          :parentDeptId="userInfo.company_id"
      ></DeptDialog>
    </el-dialog>
  </div>
</template>
<script>
import * as API from "@/api/eventManagement/index";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import { dateFormat } from "@/util/date";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import Template from "@/views/message/template/list.vue";
import { submit } from "@/api/eventManagement/index";
import snows from "@/util/snows";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
export default {
  components: {
    Template,
    UserDetpDialog,
    DeptDialog
  },
  data() {
    let isNumber = (rule, value, callback) => {
        if (value) {
            if (typeof (value) != 'number') {
                callback(new Error('请输入数字'));
            } else {
                callback();
            }
        } else {
            callback();
        }
    };
    return {
      activeName:['1','2','3','4','5'],
      active:0,
      userDialog:false,
      deptDialog:false,
      selectKey:{},
      type: "",
      accLevelText:
        "轻伤事故：指造成职工肢体伤残，或某些器官功能性或器质性轻度损伤，表现为劳动能力轻度或暂时丧失的伤害。\n" +
        "<br>一般指受伤职工歇工在一个工作日以上，但未达到重伤标准者重伤事故：指造成职工肢体残缺或视觉、听觉等器官受到严重损伤，一般能引起人体长期存在功能障碍，或劳动能力有重大损失的伤害，重伤失能损失等于和超过105个工作日；\n" +
        "<br>一般事故：是指造成3人以下死亡，或者10人以下重伤，或者1000万元以下直接经济损失的事故；\n" +
        "<br>较大事故：是指造成3人以上10人以下死亡，或者10人以上50人以下重伤，或者1000万元以上5000万元以下直接经济损失的事故；\n" +
        "<br>重大事故：是指造成10人以上30人以下死亡，或者50人以上100人以下重伤，或者5000万元以上1亿元以下直接经济损失的事故；\n" +
        "<br>特别重大事故：是指造成30人以上死亡，或者100人以上重伤（包括急性工业中毒，下同），或者1亿元以上直接经济损失的事故；\n" +
        "本款所称“以上”包括本数，“以下”不包括本数",
      showFileDialog: false,
      treeData: [],
      treeData1: [],
      acc_record_level: [],
      acc_record_type: [],
      //新增字典
      casualtyArr:[],
      accSourceArr:[],
      accWorkTypeArr:[],
      reasonArr:[],
      //end
      selectNode: {},
      formType: "",
      fileUrl: "",
      mapBox: false,
      dataForm: {
        accCode: "",
        accType: "",
        accLevel: "",
        isAutomatic: true,
        lossDeath: 0,
        lossSerious: 0,
        lossMinor: 0,
        lossIndirect: 0,
        lossTime: 0,
        //新增字段
        accOrgId:'',
        accOrgIdName:'',
        tel:'',
        casualty:'',
        orgResearchUser:'',
        orgOutResearchUser:'',
        deptIdName:'',
        deptId:'',
        accSource:'',
        reason:'',
        accResponsUserId:'',
        accResponsUserIdName:'',
        lossMiss:'',
        lossPoison:'',
        accWorkType:'',
        lossFa:'',
        accDesc:'',
        lossOther:'',
        ssCompensate:0,
        directReason:'',
        indirectReason:'',
        rootReason:'',
        lesson:'',
        remark:'',
        rectifyRequest:'',
        rectifyLimitDate:'',
        assObject:'人员',
        assDeptName:'',
        assDeptId:'',
        points:'',
        fine:'',
        eduTrain:'',
        awaitJob:'',
        rectifyDeptName:'',
        rectifyDeptId:'',
        rectifyUserName:'',
        rectifyUser:'',
        acceptDeptName:'',
        acceptDept:'',
        acceptUserName:'',
        acceptUser:'',
        accClass:'',
        otherReport:'',
        assUserId:'',
        assUserName:'',
        accTime:''
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      rules: {
        accCode: [
          {
            required: true,
            message: "请填写事故编号",
            trigger: "blur",
          },
        ],
        accTitle: [
          {
            required: true,
            message: "请填写事故标题",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请填写发生地点",
            trigger: "blur",
          },
        ],
        accTime: [
          {
            required: true,
            message: "请选择报告时间",
            trigger: "change",
          },
        ],
        accType: [
          {
            required: true,
            message: "请选择事故类别",
            trigger: "change",
          },
        ],
        accLevel: [
          {
            required: true,
            message: "请选择事故级别",
            trigger: "change",
          },
        ],
        brifDesc: [
          {
            required: true,
            message: "请填写事故描述",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "请输入事故发生的根本原因",
            trigger: "blur",
          },
        ],
        points: [{ validator: isNumber, trigger: 'blur' }],
        fine: [{ validator: isNumber, trigger: 'blur' }],
        eduTrain: [{ validator: isNumber, trigger: 'blur' }],
        awaitJob: [{ validator: isNumber, trigger: 'blur' }],
        tel:[{ validator: isNumber, trigger: 'blur' }],
        orgResearchUser:[{ required: true,message: "请输入组织内调查人员", trigger: 'blur' }],
        orgOutResearchUser:[{ required: true,message: "请输入组织外调查人员", trigger: 'blur' }],
        deptIdName:[{ required: true,message: "请选择事发部门", trigger: 'change' }],
        accSource:[{ required: true,message: "请选择事故性质", trigger: 'change' }],
        accWorkType:[{ required: true,message: "请选择事故作业类别", trigger: 'change' }],
        reason:[{ required: true,message: "请选择事故原因", trigger: 'change' }],
        accResponsUserIdName:[{ required: true,message: "请选择事故责任人", trigger: 'change' }],
        rectifyDeptName:[{ required: true,message: "请选择整改责任部门", trigger: 'change' }],
        rectifyUserName:[{ required: true,message: "请选择整改责任人", trigger: 'change' }],
        rectifyLimitDate:[{ required: true,message: "请选择整改期限", trigger: 'change' }],
        acceptDeptName:[{ required: true,message: "请选择验收部门", trigger: 'change' }],
        acceptUserName:[{ required: true,message: "请选择验收责任人", trigger: 'change' }],
        rectifyRequest:[{ required: true,message: "请输入要求整改措施", trigger: 'blur' }],
        accOrgIdName:[{ required: true,message: "请选择事发单位", trigger: 'change' }],
      },
      selectionList: [],
    };
  },
  computed: {
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.accStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
      }
      if (['process'].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit" || type == 'process') {
      this.$loading();
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.$loading();
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else {
      this.$set(this.dataForm, "reportDeptId", this.userInfo.dept_id);
      this.$set(this.dataForm, "reportDeptName", this.userInfo.deptName);
      this.$set(this.dataForm, "userId", this.userInfo.user_id);
      this.$set(this.dataForm, "userName", this.userInfo.real_name);
      this.dataForm.tel = Number(this.userInfo.phone)
      this.dataForm.accOrgIdName = this.$route.query.treeName
      this.dataForm.accOrgId = this.$route.query.treeId
      this.dataForm.accTime = dateFormat(new Date())
      console.log(this.dataForm.accTime)
      await this.handleGetCode(true);
      this.active = 0
    }
    await this.getCode("acc_record_level");
    await this.getCode("acc_record_type");
    await this.getCode("acc_record_casualty",'casualtyArr');
    await this.getCode("acc_record_source",'accSourceArr');
    await this.getCode("acc_record_work_type",'accWorkTypeArr');
    await this.getCode("acc_record_reason",'reasonArr');
  },
  methods: {
    handleGetCode(val) {
      if (val) {
        API.getCode().then((res) => {
          this.dataForm.accCode = res.data.data;
        });
      } else {
        this.dataForm.accCode = "";
      }
    },
    async getCode(code,key) {
      await API.HD_SOURCE_TYPE(code).then((res) => {
        this[key||code] = res.data.data;
      });
    },
    selectData(row) {
      this.userDialog = false;
      // this.dataForm.userId = row.id;
      // this.dataForm.userName = row.realName;
        this.dataForm[this.selectKey.id] = row.id;
        this.dataForm[this.selectKey.name] = row.realName;
        if(this.selectKey.name == 'userName'){
          this.dataForm.tel = Number(row.phone)
        }
        if(this.selectKey.name == 'rectifyUserName'){
          this.dataForm.rectifyDeptName = row.deptName
          this.dataForm.rectifyDeptId = row.deptId
        }
        if(this.selectKey.name == 'acceptUserName'){
          this.dataForm.acceptDeptName = row.deptName
          this.dataForm.acceptDept = row.deptId
        }
      console.log("进行输出人员-------------------", row);
    },
    deptBack(row){
      this.dataForm[this.selectKey.id] = row.id;
      this.dataForm[this.selectKey.name] = row.deptName;
      this.deptDialog = false
    },
    openDialog(dialogKey,keyObj) {
      this.selectKey = keyObj
      this[dialogKey] = true;
    },
    handlePreview(file) {
      snows.downloadFile1(file.link, file.name);
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isJPG =
        fileType === ".jpg" || fileType == ".png" || fileType == ".mp4";
      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png .mp4 格式!");
      }
      if ((fileType === ".jpg" || fileType == ".png") && !isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      if (fileType == ".mp4" && !isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M && isLt5M;
    },
    checkFileType1(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const fileTran = [".rar", ".zip", ".doc", ".docx", ".pdf"];
      const isJPG = fileTran.includes(fileType);

      if (!isJPG) {
        this.$message.warning("上传图片只能是 .rar  .zip .docx .pdf格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
      this.$set(this.dataForm, "treeData", this.treeData);
    },
    handleAvatarSuccess1(res, file, fileList) {
      this.treeData1.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
      this.$set(this.dataForm, "treeData1", this.treeData1);
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    async headSave(boolean) {
      console.log(this.dataForm)
      let valid = await this.$refs.dataForm.validate().catch((err) => {
        return false;
      });
      if (!valid) return this.$message.warning('有必填项未填写');
      if (this.$refs.dataForm1) {
        let valid1 = await this.$refs.dataForm1?.validate().catch((err) => {
          return false;
        });
        if (!valid1) return;
      }
      this.$loading();
      let accStatus = "";
      if (!boolean) {
        accStatus = "PREPARE";
      } else{
        accStatus = "PROCESS";
      } 
      // if (!boolean && !this.dataForm.id) {
      //   accStatus = "PREPARE";
      // } else if (boolean && !this.dataForm.id) {
      //   accStatus = "PROCESS";
      // } 
      // else if (!boolean && this.dataForm.accStatus == "PREPARE") {
      //   accStatus = "PREPARE";
      // } else if (boolean && this.dataForm.accStatus == "PREPARE") {
      //   accStatus = "APPROVED";
      // } else if (!boolean && this.dataForm.accStatus == "APPROVED") {
      //   accStatus = "APPROVED";
      // } else if (boolean && this.dataForm.accStatus == "APPROVED") {
      //   accStatus = "FINISHED";
      // }
      let url = this.formType == 'process' ? API.process : API.submit 
      url({
        ...this.dataForm,
        ...this.formType != 'process' && accStatus ?{accStatus: accStatus}:{},
        organizationId: this.$route.query.treeId,
        organizationName: this.$route.query.treeName,
        siteSituation: JSON.stringify(this.treeData),
        reportDocUrl: JSON.stringify(this.treeData1),
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: !boolean ? "保存成功" : "提交成功",
              type: "success",
            });
            // if (boolean) {
            //   this.$router.$avueRouter.closeTag();
            //   this.$router.back();
            // } else {
            //   this.dataForm.id = res.data.data;
            //   this.getPrjbasicinfoDetail();
            // }
            this.$router.$avueRouter.closeTag();
            this.$router.back();

            this.dataForm = {
              reportDate: dateFormat(new Date()),
            };
            this.$loading().close();
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      API.detail({
        id: this.dataForm.id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$loading().close();
            this.dataForm = res.data.data;
            // if(this.formType == 'process'){
            //   this.dataForm.rectifyDeptName = this.userInfo.deptName
            //   this.dataForm.rectifyDeptId = this.userInfo.dept_id
            //   this.dataForm.acceptDeptName = this.userInfo.deptName
            //   this.dataForm.acceptDept = this.userInfo.dept_id
            // }
            for(let key in this.dataForm){
              if(this.dataForm[key] == -1){
                this.dataForm[key] = ''
              }
              if(key == 'tel'){
                this.dataForm[key] = Number(this.dataForm[key])
              }
            }
            if (res.data.data.siteSituation) {
              this.treeData = JSON.parse(res.data.data.siteSituation);
              this.$set(this.dataForm, "treeData", this.treeData);
            }
            if (res.data.data.reportDocUrl) {
              this.treeData1 = JSON.parse(res.data.data.reportDocUrl);
              this.$set(this.dataForm, "treeData1", this.treeData1);
            }
            if(this.dataForm.accStatus == 'PREPARE'){
              this.active = 0
            }else if(this.dataForm.accStatus == 'PROCESS'){
               this.active = 1
            }else if(this.dataForm.accStatus == 'UNRECTIFY'){
               this.active = 2
            }else if(this.dataForm.accStatus == 'UNACCEPT'){
               this.active = 3
            }
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    handleRemove1(item, index) {
      this.treeData1.splice(index, 1);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.eventManagement-form-box {
  background-color: transparent;
  display: flex;
  height:  auto !important;
  .form-left{
    background-color: #ffffff;
    width: 125px;
    margin-right: 10px;
    .el-step.is-vertical{
      width: 99px;
    }
    .flex-container{
      padding: 12px;
    }
  }
  .form-right{
    width: calc(100% - 135px);
    background-color: #ffffff;
    .el-form{
      padding: 0 12px;
    }
    .el-collapse-item{
      padding: 0 20px;
    }
  }
  .el-image{
    margin-right: 10px;
  }
  .el-collapse-item__content{
    padding: 0 20px;
  }
  .el-collapse-item__header{
    padding-left: 20px;
  }
  .lossIndirect{
    display: inline-block;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border-radius: 4px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
