var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "IntelligentSecurity", attrs: { id: "app" } },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "dateTime" }, [
              _c("span", [_vm._v(_vm._s(_vm.currentTime))]),
              _c("span", [_vm._v(_vm._s(_vm.currentDate))]),
            ]),
            _c("div", { staticClass: "statistics" }, [
              _vm._m(0),
              _c("div", [
                _c("span", [_vm._v("在建项目/机构总数量")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formaterNumber(_vm.headerData.institutionsAmount)
                    ) + " "
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "statistics" }, [
              _vm._m(1),
              _c("div", [
                _c("span", [_vm._v("在建项目/机构总人数")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formaterNumber(
                        _vm.headerData.institutionsPersonnelAmount
                      )
                    ) + " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "statistics" }, [
              _vm._m(2),
              _c("div", [
                _c("span", [_vm._v("专职安全环保人员")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formaterNumber(_vm.headerData.protectionPersonnel)
                    ) + " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "body leftBody" }, [
            _c("div", { staticClass: "left1 modelBody" }, [
              _c("div", { staticClass: "modelBody-title-L" }, [
                _vm._v("在建项目、运营机构概况"),
              ]),
              _c(
                "div",
                { staticClass: "modelBody-body-L listBox-itemL leftTop" },
                [
                  _c("div", [
                    _c("div", [_vm._v("境内项目")]),
                    _c("div", { staticClass: "itemSL" }, [
                      _c("span", [_vm._v("项目数")]),
                      _c("span", { staticStyle: { color: "#00aaff" } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.formaterNumber(
                                _vm.InstitutionalOverview.domesticPojAmount
                              )
                            ) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "itemSL" }, [
                      _c("span", [_vm._v("人员数")]),
                      _c("span", { staticStyle: { color: "#00aaff" } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.formaterNumber(
                                _vm.InstitutionalOverview
                                  .domesticPersonnelAmount
                              )
                            ) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [_vm._v("境外项目-机构")]),
                    _c("div", { staticClass: "itemLL itemLLDB" }, [
                      _c("div", [
                        _c("span", { staticStyle: { color: "#E3F3FF" } }, [
                          _vm._v("项目/机构数"),
                        ]),
                        _c("span", { staticStyle: { color: "#00aaff" } }, [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(
                                _vm.formaterNumber(
                                  _vm.InstitutionalOverview.abroadPojAmount
                                )
                              ) +
                              "\n                                    "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "itemLL itemLLDB" }, [
                      _c("div", [
                        _c("span", { staticStyle: { color: "#E3F3FF" } }, [
                          _vm._v("人员数"),
                        ]),
                        _c("span", { staticStyle: { color: "#00aaff" } }, [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(
                                _vm.formaterNumber(
                                  _vm.InstitutionalOverview
                                    .abroadpersonnelAmount
                                )
                              ) +
                              "\n                                    "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [_vm._v("工厂")]),
                    _c("div", { staticClass: "itemSL" }, [
                      _c("span", [_vm._v("工厂数")]),
                      _c("span", { staticStyle: { color: "#00aaff" } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.formaterNumber(
                                _vm.InstitutionalOverview.factoryAmount
                              )
                            ) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "itemSL" }, [
                      _c("span", [_vm._v("人员数")]),
                      _c("span", { staticStyle: { color: "#00aaff" } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.formaterNumber(
                                _vm.InstitutionalOverview.factoryPersonnelAmount
                              )
                            ) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "left2 modelBody" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "modelBody-body-L listBox-itemL tableBox" },
                [
                  _c("div", { staticClass: "tableBoxMain" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "tableBodyFather" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tableBody",
                          attrs: { id: "tableBodyLeft" },
                        },
                        _vm._l(_vm.projectTableLeft, function (item, index) {
                          return _c("div", { staticClass: "tableTh" }, [
                            _c("span", [
                              _c("span", { staticClass: "indexIcon" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(item.orgName) +
                                  "\n                                        "
                              ),
                            ]),
                            _c("span", [_vm._v(_vm._s(item.value))]),
                            _c("span", [_vm._v(_vm._s(item.value2))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "tableBoxMain",
                    attrs: { id: "echartsL0" },
                  }),
                ]
              ),
            ]),
            _vm._m(5),
            _vm._m(6),
            _c("div", { staticClass: "left5 modelBody" }, [
              _c("div", { staticClass: "modelBody-title-S" }, [
                _vm._v("“零违章”评选情况"),
              ]),
              _c("div", { staticClass: "modelBody-body-S listBox-itemL" }, [
                _c("div", { staticClass: "itemSL" }, [
                  _c("span", [_vm._v("个人数")]),
                  _c("span", { staticStyle: { color: "#00aaff" } }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(
                          _vm.formaterNumber(
                            _vm.ViolationOfRegulations.personnelAmount
                          )
                        ) +
                        "\n                            "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "itemSL" }, [
                  _c("span", [_vm._v("班组数")]),
                  _c("span", { staticStyle: { color: "#00aaff" } }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(
                          _vm.formaterNumber(
                            _vm.ViolationOfRegulations.classAmount
                          )
                        ) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "left6 modelBody" }, [
              _c("div", { staticClass: "modelBody-title-S" }, [
                _vm._v("年度应急演练总次数"),
              ]),
              _c("div", { staticClass: "modelBody-body-S listBox-itemL" }, [
                _c("div", { staticClass: "itemSL" }, [
                  _c("span", [_vm._v("境外应急演练次数")]),
                  _c("span", { staticStyle: { color: "#00aaff" } }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.formaterNumber(_vm.drillNum.domestic)) +
                        "\n                            "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "itemSL" }, [
                  _c("span", [_vm._v("境内应急演练次数")]),
                  _c("span", { staticStyle: { color: "#00aaff" } }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.formaterNumber(_vm.drillNum.abroad)) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "earthMap center" }, [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeWordMap,
                expression: "activeWordMap",
              },
            ],
            attrs: { id: "earthMap" },
          }),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.activeWordMap,
                expression: "!activeWordMap",
              },
            ],
            attrs: { id: "wordMap" },
          }),
          _c("div", { staticClass: "wordMapBox" }, [
            _c(
              "div",
              {
                class: {
                  activeWordMap: _vm.activeWordMap,
                  wordMap: !_vm.activeWordMap,
                },
                on: {
                  click: function ($event) {
                    _vm.activeWordMap = true
                  },
                },
              },
              [_vm._v("3D地图")]
            ),
            _c(
              "div",
              {
                class: {
                  activeWordMap: !_vm.activeWordMap,
                  wordMap: _vm.activeWordMap,
                },
                on: {
                  click: function ($event) {
                    _vm.activeWordMap = false
                  },
                },
              },
              [_vm._v("世界地图")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              staticClass: "header",
              staticStyle: { "justify-content": "flex-start" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "statistics",
                  staticStyle: { "margin-left": "0" },
                },
                [
                  _vm._m(7),
                  _c("div", [
                    _c("span", [_vm._v("安全环保总投入")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formaterNumber(
                            _vm.headerData.protectionInvolvement
                          )
                        ) + " "
                      ),
                      _c("span", [_vm._v("万元")]),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "statistics" }, [
                _vm._m(8),
                _c("div", [
                  _c("span", [_vm._v("安全生产工时")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formaterNumber(_vm.headerData.safeHours)) + " "
                    ),
                    _c("span", [_vm._v("小时")]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "switchProject" },
                [
                  _c("avue-input-tree", {
                    attrs: {
                      "default-expand-all": "",
                      placeholder: "请选择所属部门",
                      clearable: false,
                      props: _vm.inputTreeProps,
                      dic: _vm.nowUserDeptList,
                    },
                    on: { change: _vm.chooseDept },
                    model: {
                      value: _vm.deptId,
                      callback: function ($$v) {
                        _vm.deptId = $$v
                      },
                      expression: "deptId",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "body rightBody" }, [
            _c("div", { staticClass: "modelBody" }, [
              _c("div", { staticClass: "modelBody-title-S" }, [
                _vm._v("安全培训覆盖率"),
              ]),
              _c("div", { staticClass: "modelBody-body-S circleChartL" }, [
                _c("div", { attrs: { id: "echartsRT1" } }),
                _c("div", [
                  _c("div", [_vm._v("培训次数")]),
                  _c("div", [_vm._v("参与培训人次")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.formaterNumber(_vm.safetyRate.trainingAmount))
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.formaterNumber(_vm.safetyRate.trainingPersonnel)
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(9),
            _vm._m(10),
            _vm._m(11),
            _vm._m(12),
            _vm._m(13),
            _c("div", { staticClass: "modelBody" }, [
              _c("div", { staticClass: "modelBody-title-S" }, [
                _vm._v("总体隐患整改率"),
              ]),
              _c("div", { staticClass: "modelBody-body-S circleChartL" }, [
                _c("div", { attrs: { id: "echartsRB3" } }),
                _c("div", [
                  _c("div", [_vm._v("培训总数")]),
                  _c("div", [_vm._v("已整改隐患")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.formaterNumber(_vm.dangerRate.dangerAmount))
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.formaterNumber(_vm.dangerRate.rectified))
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(14),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon2.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon3.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modelBody-title-L", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { flex: "1" } }, [
          _vm._v("项目机构分布情况"),
        ]),
        _c("span", { staticStyle: { flex: "1" } }, [
          _vm._v("境外项目中方外籍人员占比数"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tableHeader" }, [
      _c("span", [_vm._v("境外国家")]),
      _c("span", [_vm._v("项目/机构数")]),
      _c("span", [_vm._v("人数")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left3 modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("境外风险项目数占比统计"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsL1" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left4 modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("境外风险项目人数占比统计"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsL2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon4.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon5.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [_vm._v("培训次数统计")]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRT2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("本月公司培训次数"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRT3" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("年度安全检查统计"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRT4" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("应急预案建设率"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRB1" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [_vm._v("千人重伤率")]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRB2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modelBody" }, [
      _c("div", { staticClass: "modelBody-title-S" }, [
        _vm._v("公司隐患整改率"),
      ]),
      _c("div", {
        staticClass: "modelBody-body-S",
        attrs: { id: "echartsRB4" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }